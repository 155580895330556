import { render, staticRenderFns } from "./all_goods.vue?vue&type=template&id=295ea886&scoped=true"
import script from "./all_goods.vue?vue&type=script&lang=js"
export * from "./all_goods.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295ea886",
  null
  
)

export default component.exports