<template>
    <div id="allGoods">
        <ekk-fliter 
            placeholder="商品"
            :noExport="true"
            :noStatus="true"
            :noDate="true"
            @keyword="filters.search"
            :otherBtn="filters.otherBtn"
            @handleBtn="filters.handleBtn"
        />
        <ekk-table 
            :tableData="viewData.tableData"
            :options="viewData.options"
            :page="viewData.page"
            :feedback="viewData.feedback"
            @handle="viewData.handle"
            @handlePage="viewData.handlePage"
            @handleSort="viewData.handleSort"
        />
        
    </div>
</template>

<script>
import util from "@/utils/util";
import ekkTable from '@/components/ekk_table.vue'
import ekkFliter from '@/components/ekk_fliter.vue'

export default {
    name:'record',
    props:['showAddGoods'],
    components: { 
        ekkTable,
        ekkFliter 
    },
    data(){
        return {
            keyword:'',
            filters:{
                // 关键字筛选
                search: val => {
                    setTimeout( ()=>{
                        [this.keyword,this.viewData.page.cur] = [val, 1];
                        this.getList();
                    },300)
                },
                otherBtn:{
                    show: true,
                    text:'新建商品'
                },
                handleBtn:()=>{
                    this.$emit('handleGoods');
                },
            },
            viewData: {
                options: [
                    {prop: "barcode",label: '条形码',width: 120,overflow: true,},
                    {prop: "product_name",label: '商品名称',width: 120,overflow: true,},
                    {prop: "unit",label: '单位',width: 160,overflow: true,},
                    {prop: "brand",label: '品牌',width: 100,overflow: true,},
                    {prop: "supplier",label: '供应商',width: 100,overflow: true,},
                    {prop: "purchase_price",label: '进货价',width: 80,overflow: true,},// sort:'custom'
                    {prop: "retail_price",label: '售价',width: 80,overflow: true,},
                    {label: '操作', width: 120,center: true,slot: [
                        { type: "edit", name:'编辑',},
                        { type: "delete", name:'删除',},
                    ],},
                ],
                tableData: [],
                page: { cur: 1, size: 20, total: 0, },
                feedback: "",
                // 列表操作
                handle: (row, type) => {
                    switch (type) {
                        case "edit":
                            console.log(row.ID)
                            this.$emit('handleGoods',row.ID);
                            break;
                        case "delete":
                            this.handleDelete(row.ID);
                            break;
                        default:
                            break;
                    }
                },
                // 页码切换
                handlePage: (val) => {
                    this.viewData.page.cur = val;
                    this.getList();
                },
                // 列表排序
                handleSort: (prop, order) => {
                    // let _self = this;
                    // _self.stockList.page.cur = 1;
                    // _self.order = prop ? prop : '',
                    // _self.sort = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
                    // _self.getList();
                }
            },
        }
    },
    watch:{
        showAddGoods(val){
            if(val == false){
                [this.keyword,this.viewData.page.cur] = ['', 1];
                this.getList();
            }
        }
    },
    created(){
        this.getList();
    },
    methods:{
        //获取列表
        getList(){
            this.viewData.feedback = 'loading';
            this.viewData.tableData = [];

            this.$http.get('smart_products',{
                params:{
                    search:this.keyword,
                    page:this.viewData.page.cur,
                    pagesize:this.viewData.page.size,
                }
            }).then( res =>{
                let {lists,total} = res;
                let _listCopy = [];
                if(lists.length>0){
                    lists.forEach((item, index) => {
                        _listCopy.push({
                            ...item,
                            'purchase_price':util.getPrice(item.purchase_price),
                            'retail_price':util.getPrice(item.retail_price),
                        });
                    });
                    this.viewData.feedback = '';
                }else{
                    this.viewData.feedback = 'empty';
                }
                this.viewData.tableData = _listCopy;
                this.viewData.page.total = total;
            }).catch( err =>{
                this.viewData.feedback = 'error';
            })
        },
        // 删除商品
        handleDelete(produceId){
            this.$confirm(`确定要删除该商品？`,'提示',{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: "warning",
			}).then(() => {
                this.$http.delete(`smart_product/${produceId}`,{
                    params:{
                        produce_id:produceId,
                    }
                }).then( res =>{
                    this.$message({
                        type: "success",
                        duration: 2000,
                        message: '删除成功！',
                    });
                    this.getList();
                }).catch( err =>{
                    this.$message({
                        type: "error",
                        duration: 2000,
                        message: err.message,
                    });
                })
			}).catch(() => { });
            
        }
    }
}
</script>

<style lang="scss" scoped>

</style>