<template>
    <div id="goods">
        <el-tabs v-model="activeName"  v-show="!showAddGoods">
            <el-tab-pane label="所有商品" name="all">
                <all-goods @handleGoods='handleGoods' :showAddGoods="showAddGoods"></all-goods>
            </el-tab-pane>
            <el-tab-pane label="店铺商品" name="shop">
                <shop-goods></shop-goods>
            </el-tab-pane>
        </el-tabs>
        <add-goods :productId='productId' v-show="showAddGoods" :showAddGoods="showAddGoods" @handleSuccess='handleSuccess'></add-goods>
    </div>
</template>

<script>
import allGoods from './components/all_goods.vue';
import shopGoods from './components/shop_goods.vue';
import addGoods from './components/add_goods.vue';

export default {
    name:'order',
    components: { 
        allGoods,
        shopGoods,
        addGoods
    },
    data(){
        return {
            activeName:'all',
            productId:null,
            showAddGoods:false
        }
    },
    methods:{
        handleGoods(productId){
            this.showAddGoods = true;
            this.productId = productId;
        },
        handleSuccess(){
            this.showAddGoods = false;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>