<template>
    <div id="addGoods">
        <el-button type="text" icon="el-icon-arrow-left" @click="handleBack" class="back">返回</el-button>
        <el-form 
        ref="form" 
        :model="form" 
        :rules="rules" 
        label-width="180px"
        size="mini"
        >
            <el-form-item label="条形码" prop="barcode">
                <el-input v-model.trim="form.barcode" class="from-input-350"></el-input>
            </el-form-item>
            <el-form-item label="商品名称" prop="product_name">
                <el-input v-model.trim="form.product_name" class="from-input-350"></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="unit">
                <el-select v-model="form.unit" placeholder="请选择单位">
                    <el-option v-for="(item,i) in unitList" :key="i" :label="item" :value="item" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
                <el-input v-model.trim="form.brand" class="from-input-350"></el-input>
            </el-form-item>
            <el-form-item label="供应商" prop="supplier">
                <el-input v-model.trim="form.supplier" class="from-input-350"></el-input>
            </el-form-item>
            <el-form-item label="进货单价" prop="product_name">
                <el-input v-model="form.purchase_price" class="from-input-180"></el-input>
                <span style="margin-left:10px;color:#555">元</span>
            </el-form-item>
            <el-form-item label="建议零售价" prop="retail_price">
                <el-input v-model="form.retail_price" class="from-input-180"></el-input>
                <span style="margin-left:10px;color:#555">元</span>
            </el-form-item>
            <el-form-item label="应用到门店" prop="shop_ids">
                <el-select v-model="form.shop_ids" multiple placeholder="请选择">
                    <el-option
                        v-for="item in shopList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        props:['productId','showAddGoods'],
        data() {
            return {
                unitList: [
                    '个','盒','瓶','支','条','袋','包','盒','桶','排','罐'
                ],
                shopList:[],
                form: {
                    barcode: '',
                    product_name: '',
                    unit: '',
                    brand: '',
                    supplier: '',
                    purchase_price: '',
                    retail_price: '',
                    shop_ids: '',
                },
                rules: {
                    barcode: [
                        { required: true, message: '请输入条形码', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    product_name: [
                        { required: true, message: '请输入商品名称', trigger: 'blur' },
                    ],
                    unit: [
                        { required: true, message: '请选择单位', trigger: 'blur' },
                    ],
                    purchase_price: [
                        { required: true, message: '请输入进货单价', trigger: 'blur' },
                    ],
                    retail_price: [
                        { required: true, message: '请输入建议零售价', trigger: 'blur' },
                    ],
                }
            }
        },
        watch:{
            showAddGoods(val){
                if(val == true){
                    this.form = {
                        barcode: '',
                        product_name: '',
                        unit: '',
                        brand: '',
                        supplier: '',
                        purchase_price: '',
                        retail_price: '',
                        shop_ids: '',
                    };
                    this.resetForm();  
                }
            },
            productId(val){
                if(this.productId){
                    this.getGoodsDetail()
                }
            }
        },
        created(){
            this.getShopList();
            
        },
        methods: {
            // 获取门店列表
            getShopList(){
                this.$http.get('smart_shops',{
                    params:{}
                }).then( res =>{
                    this.shopList = res.map(v=> ({value: v.shop_id, label: `${v.shop_name}（${v.shop_id}）`}))
                }).catch( err =>{
                })
            },
            // 
            getGoodsDetail(){
                this.$http.get(`smart_product/${this.productId}`,{
                    params:{
                        product_id:this.productId
                    }
                }).then( res =>{
                    this.form = {
                        barcode: res.barcode,
                        product_name: res.product_name,
                        unit: res.unit,
                        brand: res.brand,
                        supplier: res.supplier,
                        purchase_price: res.purchase_price * 0.01,
                        retail_price: res.retail_price * 0.01,
                        shop_ids: res.shops.map(v=>v.shop_id),
                    };
                    console.log(this.form)
                }).catch( err =>{
                })
            },
            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        let {purchase_price,retail_price} = this.form;
                        if(Number(purchase_price) <= 0){
                            this.$message({
                                type: "error",
                                duration: 2000,
                                message: `价格需大于0！`,
                            });
                            return;
                        }

                        let _url = this.productId ? `/smart_product/${this.productId}` : 'smart_products'
                        this.$http.post(_url,{
                            ...this.form,
                            purchase_price: Number((purchase_price * 100).toFixed(0)),
                            retail_price:  Number((retail_price * 100).toFixed(0)),
                            shop_ids: this.form.shop_ids.join(',')
                        }).then( res =>{
                            this.$message({
                                type: "success",
                                duration: 2000,
                                message: '保存成功！',
                            });
                            this.$emit('handleSuccess')
                        }).catch( err =>{
                            this.$message({
                                type: "error",
                                duration: 2000,
                                message: '保存失败，请重试！',
                            });
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
                console.log('submit!');
            },
            resetForm() {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields();
                }
            },
            handleBack(){
                this.$emit('handleSuccess')
            }
        }
    }
</script>

<style lang="scss" scoped>
.back{
    position: absolute;
    top: 87px;
    left: 220px;
    color: #777;
}
.from-input-350{
    width: 350px;
}
.from-input-180{
    width: 180px;
}
</style>