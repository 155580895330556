<template>
    <div id="shopGoods">
        <ekk-fliter 
            placeholder="商品"
            :noExport="true"
            :statusList="filters.statusList"
            :status="shop_id"
            @status="filters.status"
            :noDate="true"
            @keyword="filters.search"
        />
        <ekk-table 
            :tableData="viewData.tableData"
            :options="viewData.options"
            :page="viewData.page"
            :feedback="viewData.feedback"
            @handle="viewData.handle"
            @handlePage="viewData.handlePage"
            @handleSort="viewData.handleSort"
        />
        <el-dialog 
        title="商品改价" 
        :visible.sync="dialogData.dialogVisible"
        width="500px">
            <el-form 
                :model="dialogData.fromData" 
                :rules="dialogData.fromRules"
                ref="fromData"
                label-width="100px"
                size="small">
                <el-form-item label="售价" prop="amount">
                    <el-input v-model="dialogData.fromData.amount" placeholder="请输入金额" style="width:200px;margin-right:10px"></el-input>
                    <span>元</span>
                </el-form-item>
            </el-form>
            
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogData.dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "@/utils/util";
import ekkTable from '@/components/ekk_table.vue'
import ekkFliter from '@/components/ekk_fliter.vue'


export default {
    name:'shopGoods',
    components: { 
        ekkTable,
        ekkFliter 
    },
    data(){
        return {
            shop_id:'',
            keyword:'',
            filters:{
                statusList: [
                    // {id: 'W0001', name: 'W0001'},
                    // {id: 'W0002', name: 'W0002'},
                    // {id: 'W0003', name: 'W0003'},
                ],
                status: val => {
                    [this.shop_id,this.viewData.page.cur] = [val, 1];
                    this.getList()
                },
                // 关键字筛选
                search: val => {
                    setTimeout( ()=>{
                        [this.keyword,this.viewData.page.cur] = [val, 1];
                        this.getList();
                    },300)
                },
            },
            viewData: {
                options: [
                    {prop: "shop_id",label: '门店',width: 60,overflow: true,},
                    {prop: "barcode",label: '条形码',width: 120,overflow: true,},
                    {prop: "product_name",label: '商品名称',width: 120,overflow: true,},
                    {prop: "unit",label: '单位',width: 80,overflow: true,},
                    {prop: "brand",label: '品牌',width: 100,overflow: true,},
                    {prop: "purchase_price",label: '进货价',width: 80,overflow: true,},// sort:'custom'
                    {prop: "shop_retail_price",label: '售价',width: 80,overflow: true,},
                    {label: '操作', width: 120,center: true,slot: [
                        { type: "price", name:'改价',},
                        { type: "delete", name:'删除',},
                    ],},
                ],
                tableData: [],
                page: { cur: 1, size: 20, total: 0, },
                feedback: "",
                // 列表操作
                handle: (row, type) => {
                    switch (type) {
                        case "price":
                            this.dialogData.dialogVisible = true;
                            this.dialogData.fromData = {
                                product_id:row.shops[0].product_id,
                                amount:row.retail_price * 0.01
                            }
                            console.log(row)
                            break;
                        case "delete":
                            this.handleDelete(row.shops[0].product_id);
                            break;
                        default:
                            break;
                    }
                },
                // 页码切换
                handlePage: (val) => {
                    this.viewData.page.cur = val;
                    this.getList();
                },
                // 列表排序
                handleSort: (prop, order) => {
                    // let _self = this;
                    // _self.stockList.page.cur = 1;
                    // _self.order = prop ? prop : '',
                    // _self.sort = order == 'ascending' ? 'asc' : order == 'descending' ? 'desc' : '';
                    // _self.getList();
                }
            },
            dialogData:{
                dialogVisible:false,
                fromData:{
                    'product_id':'',
                    "amount":"",
                },
                fromRules: {
                    amount: [
                        { required: true, message: '请输入金额', trigger: 'blur' },
                    ],
                },
            },
        }
    },
    created(){
        this.getShopList();
    },
    methods:{
        // 获取门店列表
        getShopList(){
            this.$http.get('smart_shops',{
                params:{}
            }).then( res =>{
                this.filters.statusList = res.map(v=> ({id: v.shop_id, name: v.shop_name}))
                this.shop_id = this.filters.statusList[0].id
                this.getList();
            }).catch( err =>{
            })
        },
        //获取列表
        getList(){
            this.viewData.feedback = 'loading';
            this.viewData.tableData = [];

            this.$http.get(`smart_products/${this.shop_id}`,{
                params:{
                    shop_id:this.shop_id,
                    search:this.keyword,
                    page:this.viewData.page.cur,
                    pagesize:this.viewData.page.size,
                }
            }).then( res =>{
                let {lists,total} = res;
                let _listCopy = [];
                if(lists.length>0){
                    lists.forEach((item, index) => {
                        _listCopy.push({
                            ...item,
                            'shop_id':item.shops[0].shop_id,
                            'purchase_price':util.getPrice(item.purchase_price),
                            'shop_retail_price':util.getPrice(item.shops[0].retail_price),
                        });
                    });
                    this.viewData.feedback = '';
                }else{
                    this.viewData.feedback = 'empty';
                }
                this.viewData.tableData = _listCopy;
                this.viewData.page.total = total;
            }).catch( err =>{
                this.viewData.feedback = 'error';
            })
        },
        // 删除商品
        handleDelete(produceId){
            this.$confirm(`确定要删除该商品？`,'提示',{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: "warning",
			}).then(() => {
                this.$http.delete(`smart_products/${this.shop_id}/${produceId}`,{
                    params:{
                        shop_id:this.shop_id,
                        produce_id:produceId,
                    }
                }).then( res =>{
                    this.$message({
                        type: "success",
                        duration: 2000,
                        message: '删除成功！',
                    });
                    this.getList();
                }).catch( err =>{
                    this.$message({
                        type: "error",
                        duration: 2000,
                        message: err.message,
                    });
                })
			}).catch(() => { });
            
        },
        // 改价
        submitForm() {
            this.$refs['fromData'].validate((valid) => {
            if (valid) {
                // /complete_order/:openid/:out_order_no?amount=1
                let { amount,product_id} = this.dialogData.fromData;
                const _isOrder = this.dialogData.fromData.hasOwnProperty('products');
                if(Number(amount) <= 0){
                    this.$message({
                        type: "error",
                        duration: 2000,
                        message: `金额需大于0！`,
                    });
                    return;
                }
                this.$http.post(`smart_products/${this.shop_id}/${product_id}`,{
                    retail_price:Number((amount * 100).toFixed(0)),
                }).then( res =>{ 
                    this.$message({
                        type: "success",
                        duration: 2000,
                        message: '扣款成功！',
                    });
                    this.dialogData.dialogVisible = false;
                    this.getList();
                }).catch( err =>{
                        this.$message({
                            type: "error",
                            duration: 2000,
                            message: '扣款失败，请重试！',
                        });
                    })
                
                
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>